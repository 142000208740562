import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../layout'
import Section from '../layout/section'
import './contact.css'

const Contact = () => {
  return (
    <Layout>
      <Helmet>
        <title>Motorbooks - Contato</title>
      </Helmet>
      <Section id='contact' isDark>
        <h1>Contato</h1>
        <h2>Para entrar em contato conosco, utilize qualquer um dos meios abaixo:</h2>
        <p className='blue-text'><a href='https://api.whatsapp.com/send?phone=5511988924847' target='_blank' rel='noreferrer'>Celular ou WhatsApp: (11) 98892-4847</a></p>
        <p className='blue-text'><a href='mailto:atendimento@motorbooks.com.br'>Email: atendimento@motorbooks.com.br</a></p>

        <h2>Conheça também nossas mídias sociais:</h2>
        <p className='blue-text'><a href='https://www.facebook.com/motorbooksoficial/' className='blue-text' target='_blank' rel='noreferrer'>Facebook</a></p>
        <p className='blue-text'><a href='https://www.instagram.com/motor_books/' className='blue-text' target='_blank' rel='noreferrer'>Instagram</a></p>
      </Section>
    </Layout>
  )
}

export default Contact
